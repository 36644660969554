.summary-container {
    position: absolute;
    top: 40%;
    width: 100vw;
    margin: 0 auto;
    margin-top: 50px;
}

.summary-content {
    color: white;
    font-size: 2vw;
}

.summary-content-small {
    color: white;
    font-size: 1.2vw;
}


@media screen and (max-width: 550px) {
    .summary-content {
        color: white;
        font-size: 7vw;
    }

    .summary-content-small {
        color: white;
        font-size: 4vw;
        padding-left: 10px;
        padding-right: 10px;
    }
}
.navigation {
    position: absolute;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0.5rem 0rem;
    background-color: transparent;
    box-shadow: 0 1px 1px 1px rgba(9, 9, 9, 0.23);
    backdrop-filter: blur(6px);
  }
  
  .navigation-menu {
    margin-left: auto;
  }
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  
  .navigation-menu li a {
    color: white;
    text-decoration: none;
    display: block;
    width: 100%;
  }

  .navigation-menu li a:hover {
    /* color: white;
    text-decoration: none;
    display: block;
    width: 100%; */
    font-weight: bold;
  }
  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    /* background-color: #283b8b; */
    background-color: #14C9C9;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  
  .hamburger:hover {
    /* background-color: #2642af; */
    background-color: #89E9E0;
  }
  
  @media screen and (max-width: 550px) {
    .hamburger {
      display: block;
    }
  
    .navigation-menu ul {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: calc(100vh - 60px);
      /* background-color: white; */
      background-color: rgba(29, 33, 41, 255);
      display: none;
    }
  
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
  
    .navigation-menu li a {
      color: white;
      width: 100%;
      padding: 1.5rem 0;
    }
  
    .navigation-menu li:hover {
      background-color: #4e5969;
    }
  
    .navigation-menu.expanded ul {
      display: block;
    }
  }
  


.header-container {
    position: absolute;
    left: 10%;
    top: 10%;
}

.header {
    letter-spacing: 5px;
    font-size: 5vw;
    font-weight: 100;
    color: white;
}

.subheader {
    color: white;
    font-size: 2vw;
    letter-spacing: 1;
}


@media screen and (max-width: 550px) {
    .header {
        font-size: 10vw;
    }

    .subheader {
        font-size: 6vw;
    }
}